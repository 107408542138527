/*---------------------------------------------
Version: 1.1.0

===============================================
STYLESHEET INDEXING
|
|___ Default Styles
|	|___ Variables
|	|___ Reset Styles
|	|___ Typography
|	|___ Forms Styles
|	|___ Helper Classes
|
|___ Elements Styles
|	|___ Back To Top
|	|___ Breadcrumb
|	|___ Button
|	|___ Pagination
|	|___ Section Heading
|	|___ Slick
|
|___Template Styles
|	|___ About Us
|	|___ Banner
|	|___ Brand
|	|___ Call To Action
|	|___ Case Study
|	|___ Contact
|	|___ Counter Up
|	|___ FAQ
|	|___ Pricing
|	|___ Process
|	|___ Project
|	|___ Service
|	|___ Team
|	|___ Testimonial
|	|___  Why Choose
|	|___  404 Error
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|___Dark Style
|
|___Spacing
|
|___RTL
|
|___ END STYLESHEET INDEXING

--------------------------------------------*/

/* dm-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/dm-sans-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/dm-sans-v13-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/dm-sans-v13-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/dm-sans-v13-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/dm-sans-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/dm-sans-v13-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/poppins-v20-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/poppins-v20-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v20-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/poppins-v20-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/poppins-v20-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/poppins-v20-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v20-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/poppins-v20-latin-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/*=======================================================================
1. Default Styles
=========================================================================*/
@import "default/variable";
@import "default/reset";
@import "default/typography";
@import "default/form";
@import "default/classes";

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import "elements/animate";
@import "elements/back-top";
@import "elements/breadcrumb";
@import "elements/button";
@import "elements/pagination";
@import "elements/section-heading";
@import "elements/slick";

/*=======================================================================
3. Template Styles
=========================================================================*/
@import "template/about";
@import "template/banner";
@import "template/brand";
@import "template/call-to-action";
@import "template/case-study";
@import "template/contact";
@import "template/counterup";
@import "template/faq";
@import "template/pricing";
@import "template/process";
@import "template/project";
@import "template/service";
@import "template/team";
@import "template/testimonial";
@import "template/why-choose";
@import "template/common-pages";
@import "template/splash.scss";
@import "template/onepage";

/*=======================================================================
4.Blocks Styles
=========================================================================*/
@import "header/header";
@import "blog/blog";
@import "blog/widget";
@import "footer/footer";

/*=======================================================================
5. Dark Style
=========================================================================*/
@import "dark-style/dark-version";

/*=======================================================================
6. Spacing
=========================================================================*/
@import "default/spacing";

/*=======================================================================
7. RTL
=========================================================================*/
// @import "rtl/rtl";


